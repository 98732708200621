.Login_Screen_backgroundImage {
  height: 50vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

/* ---------------- login Screen -------------------- */
.login_screen_box_hoverBox {
  margin-top: -10vh;
  display: flex;
  justify-content: center;
}
.login_screen_box {
  width: 400px;
  max-width: 90vw;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 0px 15px -10px black;
}
.login_screen_box_heading {
  text-align: center;
  font-weight: 500;
}
.login_screen_box_sec1 {
  text-align: center;
  font-size: 13px;
  color: rgba(96, 113, 127, 1);
  font-weight: bold;
  line-height: 1;
}
.login_screen_box_sec1_p1 {
  margin: 0;
}
.login_screen_box_sec1_p2 {
  /* margin: 0; */
}
.login_screen_box_sec2 {
}
.login_screen_box_sec2_lable1 {
  display: block;
  font-size: 13px;
  font-weight: 500;
  color: rgba(96, 113, 127, 1);
}
.login_screen_box_sec2_input1 {
  box-shadow: 0 0 8px -6px black;
  border-radius: 5px;
  padding: 5px 10px;
  width: 100%;
  margin-top: 5px;
  border: 1px solid rgba(96, 113, 127, 0.068);
  color: rgb(96, 113, 127);
}

.login_screen_box_sec2_input1:focus {
  outline: none;
  box-shadow: 0 0 10px -6px black;
  border: 1px solid rgba(96, 113, 127, 0.313);
}

.login_screen_box_button {
  width: 100%;
  border-radius: 10px;
  padding: 5px;
  background-color: white;
  color: rgba(106, 17, 16, 1);
  border-color: rgba(106, 17, 16, 1);
  font-weight: bold;
}

.login_screen_box_login_signup_link_box {
  text-align: center;
  display: block;
}

.login_screen_box_login_signup_link {
  text-align: center;
}

.resend-otp-text {
  cursor: pointer;
  display: block;
  text-align: right; 
}
/* ---------------- login Screen -------------------- */
