/* =============>footer styling Starts */
#myFooter-1 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px;
  background-color: rgb(106, 17, 16);
  color: rgb(255, 255, 255);
}

#myFooter-1 .footer_logo {
  height: 120px;
}

#myFooter-1 div {
  min-height: 100px;
  min-width: 200px;
  margin: 10px;
  /* border: 2px solid white; */
  padding: 20px;
  padding-left: 40px;
}

#myFooter-1 div span {
  width: 250px;
  text-align: justify;
  display: block;
}

#myFooter-1 div ul {
  padding-left: 0 !important;
}

#myFooter-1 div ul li * {
  text-decoration: none;
  font-weight: 300;
  color: rgb(255, 255, 255);
  font-size: 14px;
}

#myFooter-1 div ul li {
  list-style: none;
  margin-top: 5px;
}

.copyright_message {
  text-align: center;
  background-color: rgb(241, 104, 69);
  color: rgb(255, 255, 255);
  padding: 10px;
}

@media screen and (max-width: 768px) {
  #myFooter-1 div {
    min-height: 100px;
    width: 90vw;
  }
  #myFooter-1 div span {
    width: 80%;
  }
}

@media screen and (max-width: 1080px) {
  #myFooter-1 div {
    min-height: 100px;
    min-width: 35vw;
  }
}

/* =============>footer styling end */

.footer-social-icons {
  display: flex !important;
  gap: 15px;
  padding: 10px;
}

.footer-social-icons span {
  display: inline !important;
  width: fit-content !important;
}

.footer-social-icons i {
  color: rgba(106, 17, 16, 1);
  background-color: rgb(255, 255, 255);
  padding: 5px;
  border-radius: 50%;
  font-size: 15px;
  width: 30px;
  text-align: center;
}

.footer-downloadourapp_box h5 {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
  margin-top: 10px;
}

.footer-downloadourapp {
  display: flex !important;
  gap: 15px;
  padding: 10px;
}

.footer-downloadourapp a {
  color: white !important;
  text-decoration: none;
  display: inline !important;
  width: fit-content !important;
}

.footer-downloadourapp a i {
  margin-right: 4px;
}

.copyright_message a {
  text-decoration: none;
  color: white !important;
}

.mobile_navigation_box_container {
  background-color: rgb(241, 104, 69);
}

.mobile_navigation_box {
  display: flex;
  justify-content: space-between;
}

.mobile_navigation_box a {
  text-decoration: none;
}

.mobile_navigation_box a > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mobile_navigation_box > div img {
  height: 25px;
  width: 25px;
}

.mobile_navigation_box .mobile_footer_title {
  color: white;
  font-weight: 500;
  font-size: 0.9rem;
}

.copyright_message {
  display: none;
}

.header_main_sec3 {
  display: none;
}

.header_main_sec3_hide_div {
  display: flex;
}

.extraFooterSpace {
  min-height: 100px;
  background-color: rgb(106, 17, 16);
}

.mobile_navigation_box_container {
  position: fixed;
  bottom: 0;
  width: 100vw;
  z-index: 100;
}

@media screen and (min-width: 991px) {
  .mobile_navigation_box_container {
    display: none;
  }

  .copyright_message {
    display: block;
  }

  .header_main_sec3 {
    display: flex;
  }

  .header_main_sec3_hide_div {
    display: none;
  }
}

@media screen and (max-width: 990px) {
  .main_header_sec1 {
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  ::-webkit-scrollbar {
    display: none;
  }
}
