.react-multiple-carousel__arrow--right {
  right: calc(0% + 1px) !important;
}

.react-multiple-carousel__arrow--left {
  left: calc(0% + 1px) !important;
}

/* ------------------------ slider css -------------------- */

.item-slider-box {
  background-color: rgb(106, 17, 16);
}

.itemSlider {
  border-radius: 10px;
  background-color: rgb(237, 220, 239);
  margin: 10px;
  padding: 5px;
  display: flex;
  gap: 5px;
  justify-self: center;
  align-items: center;
  /* flex-wrap: wrap; */
}

.itemSlider img {
  height: 50px;
  max-width: 60px;
  border-radius: 10px;
}

.itemSlider span {
  color: black !important;
  font-weight: 500;
  max-height: 50px;
  font-size: 13px;
  overflow: hidden;
  /* min-width: 100px !important; */
}

.react-multiple-carousel__arrow:hover {
  background: rgba(57, 56, 56, 0.5) !important;
}

.react-multiple-carousel__arrow {
  background: rgba(57, 56, 56, 0.1) !important;
}

.react-multiple-carousel__arrow:hover .react-multiple-carousel__arrow::before {
  color: rgb(255, 255, 255) !important;
}

/* ------------------------ slider css -------------------- */

.HomeSection2 {
  background-color: rgb(255 232 209 / 91%);
}

.HomeSection2 img {
  width: 90%;
}

.HomeSection2_sec {
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}

.HomeSection2 .sec_1 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.HomeSection2 .sec_1>div {
  width: 80%;
  font-size: 20px;
}

@media screen and (max-width: 768px) {
  .HomeSection2 .sec_1>div {
    font-size: 15px;
    width: 60%;
  }
}

@media screen and (max-width: 575px) {
  .HomeSection2 .sec_1 {
    justify-content: center !important;
  }
}

.HomeSection2_hoverBox {
  margin-top: -50px;
  position: relative;
}

.HomeSection2_hoverBox .row {
  min-height: 100px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.HomeSection2_hoverBoxSec1 {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 20px;
  max-width: 500px;
  margin: auto;
}

.HomeSection2_hoverBoxSec1_div {
  display: flex !important;
  justify-content: space-between;
  min-height: 70px;
}

.HomeSection2_hoverBoxSec1_left {
  width: 30%;
  margin: auto;
  color: white;
  text-align: center;
}

.HomeSection2_hoverBoxSec1_right {
  width: 65%;
  margin: auto;
  color: white;
  text-align: center;
}

.HomeSection2_hoverBoxSec2 {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 20px;
  position: relative;
  max-width: 500px;
  margin: auto;
}

.HomeSection2_hoverBoxSec2_div {
  min-height: 70px;
  z-index: 1;
  position: relative;
}

.HomeSection2_hoverBoxSec2_top {
  color: white;
  text-align: center;
}

.HomeSection2_hoverBoxSec2_imgBox {
  text-align: center;
}

.HomeSection2_hoverBoxSec2_imgBox img {
  height: 40px;
}

/* .HomeSection2_hoverBoxSec2_imgBox_img1 {
}
.HomeSection2_hoverBoxSec2_imgBox_img2 {
} */

.HomeSection2_hoverBoxSec2_mobileImage {
  position: absolute;
  top: 5%;
  left: 0;
  height: 90%;
  width: 100%;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
}


/* =============== slider main css ====================== */
#start_width_0px_HomeSection2Cmp1 {
  min-width: 0 !important;
  max-height: 0 !important;
}

.height__0 {
  max-height: 0;
}

/* update as per requirement */
.react_slider_2l4234nl_HomeSection2Cmp1 {
  transition: 500ms;
}

.react_slider_2l4234nl_HomeSection2Cmp1 img {
  width: 100%;
  height: 100%;
}

/* update as per requirement */
/* =============== slider main css ====================== */

/* ================================ section 3 ================================ */

.home_section3_main_container {
  background-color: rgb(255, 245, 235);
}

.home_section3 {
  display: flex;
  min-height: 100px;
  gap: 10px;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.home_section3_item {
  border: 1px solid black;
  border-radius: 10px;
  padding: 10px;
  min-width: 220px;
  background-color: rgb(255, 255, 255);
}

@media screen and (max-width: 450px) {
  .home_section3_item {
    width: 90vw;
  }
}

.home_section3_item_left {
  background-color: transparent !important;
}

.home_section3_item_left button {
  background-color: rgb(255, 255, 255);
}

.home_section3_item_sec1 span {
  background-color: rgb(106, 17, 16);
  color: white;
  padding: 5px 10px;
  border-radius: 50px;
  font-weight: 500;
  font-size: 11px;
}

.home_section3_item_sec2 span {
  background-color: rgb(106, 17, 16);
  width: 60%;
  aspect-ratio: 1;
  margin: auto;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home_section3_item_sec2 span img {
  width: 50%;
}

.home_section3_item_sec3 {
  font-weight: bold;
  margin-top: 25px;
  margin-left: 10px;
  color: rgb(106, 17, 16);
}

.home_section3_item_sec4 {
  color: rgb(106, 17, 16);
  font-weight: bold;
  margin-left: 10px;
}

.home_section3_item_view_morebox {
  border: none !important;
  min-width: 0 !important;
  max-width: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home_section3_item_view_morebox_button_box {
  display: none;
}

.home_section3_titleBox {
  display: none;
}

@media screen and (max-width: 1199px) {
  .home_section3_item_view_morebox {
    display: none;
  }

  .home_section3_titleBox {
    display: block;
  }

  .home_section3_item_view_morebox_button_box {
    display: block;
    display: flex;
    justify-content: center;
  }
}

.home_section3_item_view_morebox_heading {
  color: rgb(106, 17, 16);
  font-weight: bold;
}

.home_section3_item_view_morebox_small_desc {
  font-size: 13px;
}

.home_section3_item_view_morebox_button {
  /* border: none; */
  background: none;
  color: rgb(106, 17, 16);
  font-weight: bold;
  font-size: 15px;
  border: 1.5px solid rgb(106, 17, 16);
  padding: 5px 10px;
  margin: 20px 0;
}

/* ================================ section 3 ================================ */

/* ================================ section 3 ================================ */
.home_section_4_sec1 {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .home_section_4_sec1_div {
  width: 70%;
} */

.home_section_4_text1 {
  font-style: italic;
  font-weight: 600;
}

.home_section_4_text2 {
  background-color: rgb(106, 17, 16);
  color: white;
  font-weight: 400;
  padding: 5px;
}

.home_section_4_text3 {
  max-width: 90%;
}

.home_section_4_sec2 {
  text-align: center;
}

.home_section_4_sec2 img {
  width: 90%;
}

/* ================================ section 3 ================================ */

/* ================================ Home Section 5 ================================ */
.HomeSection5 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  margin: 5px;
  box-shadow: 0 0 15px -13px black;
  border-radius: 20px;

  background-repeat: no-repeat;
  background-size: 90% 30%;
  background-position: top center;
}

@media screen and (max-width: 450px) {
  .HomeSection5 {
    padding: 30px;
  }
}

@media screen and (max-width: 350px) {
  .HomeSection5 {
    padding: 20px;
  }
}

.HomeSection5_imge_box {
  width: 30%;
}

.HomeSection5_imge {
  width: 90%;
  border-radius: 50%;
}

.HomeSection5_innerdiv {
  width: 65%;
}

.HomeSection5_testimonial {
  font-size: 14px;
  color: rgb(45, 45, 45) !important;
  font-weight: 400;
}

.HomeSection5_name {
  color: rgb(47, 47, 47) !important;
  font-size: 15px !important;
  font-weight: bold;
  margin-bottom: 0px !important;
}

.HomeSection5_designation {
  color: #9b9b9b;
  font-size: 13px;
}

@media screen and (max-width: 768px) {
  .HomeSection5_testimonial {
    font-size: 11px;
    margin-bottom: 5px;
  }

  .HomeSection5_name {
    font-size: 13px;
  }

  .HomeSection5_designation {
    /* color: #4e4e4e; */
    font-size: 10px;
  }
}

/* ================================ Home Section 5 ================================ */

/* ================================ Home Section 6 ================================ */
.home_section6 {
  display: flex;
  gap: 10px;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.home_section6_item {
  width: 250px;
}

.home_section6_item {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.home_section6_image {
  height: 170px;
}

.home_section6_heading {
  font-size: 17px;
  font-weight: 600;
  color: rgb(106, 17, 16);
}

.home_section6_short_desc {
  font-size: 14px;
  max-width: 80%;
}

/* ================================ Home Section 6 ================================ */

/* ================================ Home section 1 ================================ */

.main_puja_form_backgrounddiv {
  background-color: rgb(106, 17, 16);
  height: 50%;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  /* z-index: -1; */
}

.main_puja_form {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  background-color: white;
  padding: 10px;
  border-radius: 50px;
  position: relative;
  z-index: 10;
  box-shadow: 0px 0px 20px -13px black;
}

.main_puja_form>span {
  display: flex;
  align-items: center;
  border: 1px solid rgb(116, 116, 116);
  border-radius: 100px;
  overflow: hidden;
  padding-left: 10px;
  padding-right: 10px;
}

.main_puja_form>.button_span {
  border: none !important;
}


.main_puja_form>.button_span {
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .main_puja_form>span {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 5px;
    text-align: center;
  }
}

.main_puja_form select {
  border: none;
}

.main_puja_form select:hover {
  outline: none;
  border: none;
  box-shadow: none !important;
}

.main_puja_form input {
  border: none;
}

.main_puja_form input:hover {
  outline: none;
  border: none;
  box-shadow: none !important;
}

.main_puja_form>span {
  margin: 0px 10px;
}

.main_puja_form .book_button {
  text-align: center;
  background-color: rgb(106, 17, 16);
  color: white;
  border-radius: 50px;
  border: none;
  padding: 9px 20px;
  margin-right: -10px !important;
  font-size: 12px !important;
}

.main_puja_form img {
  height: 22px;
  margin-right: 5px;
}

@media screen and (max-width: 768px) {
  /* .main_puja_form span button {
    font-size: 10px !important;
  } */

  .main_puja_form img {
    height: 18px;
    margin-right: 4px;
  }
}

@media screen and (max-width: 500px) {
  /* .main_puja_form span button {
    font-size: 8px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-left: 0px;
    padding-right: 0px;
  } */

  .main_puja_form img {
    height: 15px;
    margin-right: 3px;
  }
}

@media screen and (max-width: 350px) {
  .main_puja_form>span {
    margin: 0px 0px;
  }

  .main_puja_form .book_button {
    margin-right: 0px !important;
    padding: 8px 10px;
  }
}

/* ================================ Home section 1 ================================ */

/* ================================ Home section 8 ================================ */

.homeSection8PanditItem {
  padding: 10px;
}

.homeSection8PanditItem_innerDiv {
  padding: 10px;
  background-color: rgb(255, 245, 235);
  border-radius: 10px;
}

.homeSection8PanditItem_borderDiv {
  border: 1.5px solid rgb(106, 17, 16);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: rgb(255, 255, 255);
}

.homeSection8PanditItem_img {
  height: 100px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 3px solid rgb(106, 17, 16);
}

.homeSection8PanditItem_name {
  margin-top: 20px;
  font-size: 17px;
  font-weight: bold;
  color: rgb(106, 17, 16);
  text-align: center;
}

.homeSection8PanditItem_specializaion {
  font-size: 17px;
  font-weight: 500;
}

/* ================================ Home section 8 ================================ */

.front_package_info_heading {
  font-weight: bold;
}

.front_package_info p {
  font-weight: 500;
}

/* ======================================================================================== */
@media screen and (min-width: 1200px) {
  .main_puja_form_backgrounddiv_container {
    position: absolute;
    top: 0 !important;
    padding: 0 !important;
  }

  .main_puja_form_hoverDiv {
    position: absolute;
    top: 0;
    z-index: 10;
    width: 100vw;
    left: 0;
    display: flex;
    justify-content: center;
    height: 80px;
    align-items: center;
  }

  .main_puja_form_hoverDiv .main_puja_form {
    width: 45%;
  }
}

/* ======================================================================================== */


.packageDetails_box_item_item .accordion-button {
  background-color: transparent !important;
  box-shadow: none;
}

.packageDetails_box_item_item .accordion-button:focus {
  box-shadow: none !important;
}


/* =========================== Homesection 9 ================================================= */
/* =============== slider main css ====================== */
#start_width_0px_HomeSection9 {
  min-width: 0 !important;
  max-height: 0 !important;
}

.height__0 {
  max-height: 0;
}

/* update as per requirement */
.react_slider_2l4234nl_HomeSection9 {
  transition: 500ms;
}

.react_slider_2l4234nl_HomeSection9 img {
  width: 100%;
  height: 100%;
}

/* update as per requirement */
/* =============== slider main css ====================== */


.packageViewSliderCmp {
  border: 1px solid black;
  border-radius: 10px;
}

/* =========================== Homesection 9 ================================================= */