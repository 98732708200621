@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
}

#root {
  /* width: 100vw; */
  overflow-x: hidden !important;
}

.normal_link {
  text-decoration: none !important;
}

.wishlistPage_heading u {
  color: rgb(106, 17, 16);
  font-weight: bold;
}

.wishlistPage_heading span {
  font-weight: bold;
  color: rgb(106, 17, 16);
}

h3 {
  font-weight: bold !important;
}

label {
  color: rgb(78, 88, 97);
  font-weight: 500 !important;
}

/* ====================================================================== */

.bannerSection {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  text-align: center;
  padding: 40px 0;
  position: relative;
}

.bannerSection_blurBackground {
  position: absolute;
  height: 100%;
  background: rgb(255 248 232 / 60%);
  width: 100%;
  top: 0;
  z-index: 1;
}

.bannerSection_content {
  position: relative;
  z-index: 2;
}

.bannerSection_heading {
  font-weight: bold;
  color: rgb(106, 17, 16);
}

.sectionHeading4 h4 {
  color: rgb(106, 17, 16);
  margin-top: 20px;
}

.sectionHeading4 p {
  font-weight: 500;
  /* color: rgb(106, 17, 16); */
}

@media screen and (max-width: 991px) {
  .hide-on-col-md {
    display: none;
  }
}

@media screen and (min-width: 992px) {
  .hide-on-col-lg {
    display: none;
  }
}

a {
  text-decoration: none;
}

.fa_center {
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.packageDetails_box {
  background-color: rgb(106, 17, 16);
  border-radius: 10px;
  padding: 20px;
}

.packageDetails_box_item {
  height: 100%;
  background-color: rgb(106, 17, 16);
}

.packageDetails_box_item_item .accordion-item {
  background-color: rgb(244, 244, 244);
}

.packageDetails_box_item_item {
  background-color: rgb(255, 245, 235);
  padding: 20px;
  height: 100%;
}

.packageDetails_box_item_item p,
span {
  font-weight: 400;
  margin: 0;
  /* color: rgb(33, 37, 41) !important; */
  font-weight: 400 !important;
}

.packageDetails_box_item_item p b {
  font-weight: bold;
}

.packageDetails_box_item_item_description {
  font-size: 0.9rem;

}

.packageDetails_box_item_item_book_button {
  background-color: rgb(106, 17, 16);
  width: 100%;
  font-weight: bold;
  color: rgb(255, 245, 235);
}

.packageDetails_box_item_item_book_button_selected {
  background-color: rgb(49, 150, 47) !important;
}