/* .blogPage_item {
   
} */

.blogPage_item_sec1 {
  padding: 0;
  margin: 0;
  color: rgb(106, 17, 16);
  font-weight: 600;
}
.blogPage_item_sec2 {
  padding: 0;
  margin: 0;
  color: rgb(78, 88, 97);
  font-weight: 400;
}

.blog_section h3 {
  color: rgb(106, 17, 16);
}
.blog_section h5 {
  /* font-weight: 400; */
  color: rgb(106, 17, 16);
}
.blog_section p {
  font-weight: 400;
  color: #28507f;
  text-align: justify;
}
