/* .allProducts {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
} */

/* .allProducts_item { 
  min-width: 320px;
} */

/* @media screen and (max-width: 350px) {
  .allProducts_item {
    min-width: 260px;
  }
} */

.allProducts_item_Box {
  border: 1px solid black;
  border-radius: 5px;
  overflow: hidden;
}

.allProducts_item_img_imgBox {
  aspect-ratio: 1;
  background-color: red;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.allProducts_item_img {
  width: 100%;
  display: none;
}

.allProducts_item_description {
  text-align: center;
}
.allProducts_item_name {
  color: rgb(106, 17, 16);
  font-weight: bold;
  margin: 0;
}
.allProducts_item_price {
  font-weight: 600;
}
.allProducts_item_addToCartButton {
  width: 100%;
  border: none;
  background-color: rgb(106, 17, 16);
  color: white;
  padding: 5%;
}

/* ----------- product modal */
/* .product_modal {
   
} */

@media screen and (min-width: 768px) {
  .product_modal .modal-dialog {
    min-width: 90vw;
    position: relative;
  }
}

@media screen and (min-width: 1199px) {
  .product_modal .modal-dialog {
    min-width: 75vw;
  }
}

.product_modal .modal-dialog .modal-content {
  border-radius: 0;
}

.product_modal_selectedImage {
  aspect-ratio: 1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 10px;
}

.product_modal_PendingImage {
  aspect-ratio: 1;
  width: 20%;
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  margin-bottom: 10px;
}

.product_modal_desc_Box1 {
  justify-content: space-between;
  align-items: center;
}

.product_modal_desc_Box1_sec1 {
  color: white;
  background-color: black;
  padding: 0 5px;
  border-radius: 5px;
}

.product_modal_desc_Box1_sec2 {
  position: absolute;
  top: 15px;
  right: 20px;
}
.product_modal_desc_Box1_sec2 i {
  font-size: 30px;
}

.product_modal_desc_Box2 {
  display: flex;
  justify-content: space-between;
}
.product_modal_desc_Box2_sec1 {
  width: 45%;
}
.product_modal_desc_Box2_sec2 {
  width: 45%;
}

.product_modal_desc_name {
  color: rgb(106, 17, 16);
  font-weight: bold;
}
.product_modal_desc_description {
  font-size: 13px;
  font-weight: 400;
}

.product_modal_desc_Box2_sec1_heading {
  font-weight: bold;
}

.product_modal_desc_Box2_sec1_body {
  padding: 5px;
}

.product_modal_desc_Box2_sec1_body_sec1 {
  font-weight: 600;
  font-size: 20px;
}
.product_modal_desc_Box2_sec1_body_sec2 {
  font-size: 13px;
  margin-left: 10px;
}

.product_modal_desc_Box2_sec1_body_icon span {
  min-width: 30px;
  height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 3px solid black;
  border-radius: 40px;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .product_modal_desc_Box2_sec1_body_icon span {
    min-width: 20px;
    height: 20px;
    font-size: 12px;
  }
}

.product_modal_desc_Box2_sec1_body_white {
  margin: 0 10px;
}
.product_modal_desc_Box2_sec1_body_black {
  background-color: black;
  color: white;
}

/* .product_modal_button_box{
    
} */

.product_modal_button_box button {
  border: 2px solid black;
  padding: 5px 20px;
  margin-top: 10px;
}

@media screen and (max-width: 450px) {
  .product_modal_button_box button {
   width: 90%;
   margin: auto;
   margin-top: 10px;
   display: flex;
   justify-content: center;
  }
}

.product_modal_button_cart {
  background-color: black;
  color: white;
  font-weight: bold;
}

.product_modal_button_wishlist {
  margin-left: 10px;
  font-weight: bold;
  background-color: white;
}

.product_modal_button_wishlist i {
  font-weight: bold;
  margin: 0 5px;
}

/* .product_modal_error_box_sec1 {
  font-weight: bold;
} */
.product_modal_error_box_sec2 a {
  font-weight: 500;
}
/* ----------- product modal */
