.myorderbox {
  background-color: rgb(106, 17, 16);
  margin: 10px;
  padding: 20px;
  border-radius: 10px;
}

.myorderbox-title {
  font-weight: 500;
  color: rgb(255, 255, 255);
}
.myorderbox-value {
  font-weight: 400;
  color: rgb(222, 222, 222);
}
