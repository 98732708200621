.about_us_box h3 {
  color: rgba(106, 17, 16, 1);
}

.about_us_box .imgBox {
  display: flex;
  justify-content: center;
}
.about_us_box img {
  width: 100%;
}

.about_us_box p {
  font-weight: 400;
  font-size: 0.9rem;
  color: gray;
  text-align: justify;
}

.about_us_box h5 {
  font-weight: 600;
  color: rgba(106, 17, 16, 1);
}
