.contact_us_mainContainer h2,
.contact_us_mainContainer h4 {
  color: rgb(245, 99, 48);
  font-weight: 600;
}
.contact_us_mainContainer h6 {
  font-weight: 500;
}

.contact_us_mainContainer form label {
  font-weight: 500;
  color: black;
  display: block;
}
.contact_us_mainContainer form input,
.contact_us_mainContainer form textarea {
  width: 100%;
  border: none;
  outline: none;
  background-color: rgb(255, 245, 240);
  padding: 10px;
  border-radius: 6px;
  font-weight: 500;
  color: rgb(75, 75, 75);
}

.contact_us_mainContainer form button {
  background-color: rgb(106, 17, 16);
  color: white;
  border-radius: 100px;
  padding: 10px 50px;
  font-size: 0.8rem;
}
.contact_us_mainContainer form button:hover {
  background-color: rgb(245, 99, 48);
  color: white;
}

.contact_us_mainContainer address {
  font-weight: 500;
  color: grey;
}
.contact_us_mainContainer .contact_info {
  font-weight: 600;
  color: rgb(245, 99, 48);
  margin-top: 5px;
}
