.boldTh {
  font-weight: bold;
}

.cart_align-div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.cart_table-row h6 {
  display: inline;
  font-weight: 600;
}

.cart_product-img {
  display: inline-block;
  height: 50px;
  aspect-ratio: 1;
  border-radius: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.cart_quantity {
  display: inline-block;
  height: 27px;
  width: 27px;
  text-align: center;
  border-radius: 50%;
  border: 2px solid black;
}

.cart_quantity-icons {
  display: inline-block;
  height: 25px;
  width: 25px;
  text-align: center;
  border-radius: 50%;
}

/* ================================= */
.cart_summary_box {
  border: 1px solid black;
  border-radius: 10px;
  padding: 5%;
}
.cart_summary_box .section1 {
  display: flex;
  justify-content: space-between;
}
.cart_summary_box .section1 h6 {
  font-weight: 600;
}
.cart_summary_box .section1 h6 .subtotal {
  font-weight: 500;
  color: rgb(97, 97, 97);
}

.cart_proceed-btn {
  background-color: black;
  color: white;
  width: 100%;
}
